import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SidebarItem = ({
  label,
  Icon,
  to,
  active,
  collapsed,
  unActiveColor = 'text-secondary',
  isNew,
  isBold,
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={`w-full rounded-md ml-4 pr-4 ${
        active ? 'bg-primary bg-opacity-10 text-primary' : unActiveColor
      } ${isBold ? 'font-semibold' : ''}`}
    >
      <Link to={to} className='hover:text-primary'>
        <div
          className={`flex flex-row gap-4 my-2 items-center ${
            collapsed ? 'justify-center' : 'mr-24 ml-4'
          }`}
        >
          <Icon className='h-6 flex-shrink-0' />
          {!collapsed && label}
          {isNew && !collapsed
            // <span className='text-error ml-2 animate-pulse'>
            //   {t('students.new')}
            // </span>
          }
        </div>
      </Link>
    </div>
  );
};

export default SidebarItem;
