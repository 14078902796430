import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locale/en/translation.json';
import kz from './locale/kz/translation.json';
import ru from './locale/ru/translation.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru: ru,
    },
    ns: ['translations'],
  });

export default i18n;
