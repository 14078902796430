import React from 'react';
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown';
import ChildrenChips from './ChildrenChips';
import NotificationsDropdown from './NotificationsDropdown';

const Navbar = ({ user }) => {
  return user ? (
    <div className='flex flex-row h-navbar items-center px-6 justify-between'>
      <div className=''>
        {user?.roles?.some((r) => r?.code === 'PARENT') && <ChildrenChips />}
      </div>
      <div className='flex flex-row items-center gap-4'>
        <NotificationsDropdown />
        {/* <LanguageDropdown /> */}
        <p className='font-bold text-xl'>{user?.first_name} {user?.last_name}</p>
      </div>
    </div>
  ) : null;
};

export default Navbar;
